import React from 'react';
import "./product.css";
import k_hemp from '../../assets/fabrics/khaddar/k_hemp.jpg'
import btc from '../../assets/fabrics/btc/btc.jpg'
import c_kora from '../../assets/fabrics/canvas/c_kora.jpg'
import fb_red from '../../assets/fabrics/fishbone/fb_red.jpg'
import u_purple from '../../assets/fabrics/fishbone/u_purple.jpg'
import ohm_shirt from '../../assets/product/ohm_shirt.png'
import totebag_canvas from '../../assets/product/totebag_canvas.jpeg'


const Product = () => {
  return (
    <>

<div id="product" className="flex items-center justify-center py-4 md:py-8 flex-wrap">
    <button type="button" className="text-blue-700 hover:text-white border border-blue-600 bg-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800">All categories</button>
    <button type="button" className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800">Khaddar</button>
    <button type="button" className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800">Shyama</button>
    <button type="button" className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800">BTC</button>
    <button type="button" className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800">Canvas</button>
</div>
<div className="mr-12 ml-12 grid grid-cols-2 md:grid-cols-3 gap-4">
<button className='relative' data-popover-target="popover-default">
        <img className="h-auto max-w-full rounded-lg" src={k_hemp} alt=""/>
        <div className="img-caption absolute py-2.5 bottom-0 inset-x-0 text-white text-lg text-center font-mono text-gray-600">Khaddar</div>
    </button>
    {/*  */}
    <div data-popover id="popover-default" role="tooltip" className="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
        <h5 className="font-semibold text-gray-900 dark:text-white">Shirt</h5>
    </div>
    <div className="px-3 py-2">
      <img className="h-auto max-w-full rounded-lg" src={ohm_shirt} alt=""/>

    </div>
    <div data-popper-arrow></div>
</div>
{/*  */}
<button className='relative' data-popover-target="popover-khaddar">
        <img className="h-auto max-w-full rounded-lg" src={c_kora} alt=""/>
        <div className="img-caption absolute py-2.5 bottom-0 inset-x-0 text-white text-lg text-center font-mono text-gray-600">Canvas</div>
    </button>
    {/*  */}
    <div data-popover id="popover-khaddar" role="tooltip" className="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
        <h5 className="font-semibold text-gray-900 dark:text-white">Tote Bag</h5>
    </div>
    <div className="px-3 py-2">
      <img className="h-auto max-w-full rounded-lg" src={totebag_canvas} alt=""/>

    </div>
    <div data-popper-arrow></div>
</div>
    <div>
        <img className="h-auto max-w-full rounded-lg" src={btc} alt=""/>
    </div>
    <div>
        <img className="h-auto max-w-full rounded-lg" src={fb_red} alt=""/>
    </div>
    <div>
        <img className="h-auto max-w-full rounded-lg" src={u_purple} alt=""/>
    </div>
    
</div>

    </>
  )
}

export default Product;