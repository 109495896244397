import React from 'react';
import "./App.css"
import { Header, Footer, Product, Contact } from './containers';
import {Navbar} from "./components"

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Product />
      <Contact />
      <Footer />
      
    </div>
  );
};

export default App;
